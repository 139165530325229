<template>
  <vx-table
    ref="tableRef"
    :resource="resource"
    :columns="columns"
    :filters="filters"
  />
</template>

<script>
import {
  VxTable,
  VxCellDate,
  VxCellPlayerAvatar,
  VxCellButtons,
  VxCellBadges,
  VxCellAntifraudPoints
} from '@/components/table'
import {
  antifraud,
  boolStatuses,
  passDataToResource,
  playerStatuses
} from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'
import { boolStatusesOptions, boolStatusFormat } from '@/services/utils'
import { isEmpty } from 'lodash'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { ref } from '@vue/composition-api'
import { useToast } from '@/plugins/toastification'

export default {
  name: 'Records',
  components: {
    VxTable,
    VxCellDate,
    VxCellPlayerAvatar,
    VxCellBadges,
    VxCellAntifraudPoints
  },
  setup () {
    const toast = useToast()
    const tableRef = ref(null)
    const resource = passDataToResource(antifraud.getAllRecords, {
      requestParams: {
        params: { include: 'player,player.activeAntifraudActivation,points' }
      }
    })

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'player',
        label: 'Player',
        component: VxCellPlayerAvatar
      },
      {
        key: 'points',
        label: 'Points Log',
        component: VxCellAntifraudPoints
      },
      {
        key: 'total_points',
        sortable: true
      },
      {
        key: 'is_disabled',
        label: 'Disabled',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [boolStatuses['0']]: 'success',
            [boolStatuses['1']]: 'danger'
          }
        },
        formatter: (isDisabled) => boolStatusFormat(isDisabled)
      },
      {
        key: 'created_at',
        sortable: true,
        component: VxCellDate
      },
      {
        key: 'updated_at',
        sortable: true,
        component: VxCellDate
      },
      {
        key: 'actions',
        label: 'Manual Ban',
        component: VxCellButtons,
        tdAttr: (value, key, { player }) => ({
          buttons: manualBlockButton(player)
        })
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'player_id',
        type: filterTypes.number
      },
      {
        key: 'is_disabled',
        label: 'Disabled',
        type: filterTypes.select,
        options: boolStatusesOptions,
        optionsLabel: 'label',
        reduce: ({ value }) => value
      }
    ]

    const playerCanBeBlocked = ({ profile: { status }, is_disabled, active_antifraud_activation }) => {
      const playerIsActive = status === playerStatuses.active
      const noActivation = isEmpty(active_antifraud_activation)
      return playerIsActive && !is_disabled && noActivation
    }

    const blockPlayer = async (item, [err]) => {
      if (err && err?.data?.message) {
        toast.error(err.data.message)
      }
    }

    const manualBlockButton = (player) => {
      if (!playerCanBeBlocked(player)) return []

      const createActivationResource = passDataToResource(antifraud.createActivation, {
        requestParams: { data: { player_id: player.id } }
      })

      return [
        {
          ...buttons.block,
          resource: createActivationResource,
          onClick: blockPlayer
        }
      ]
    }

    return {
      tableRef,
      resource,
      columns,
      filters
    }
  }
}
</script>
